

export const Title = () => {
  return (
    <div className="sidebar-brand">
    <span >
        <img src="https://res.cloudinary.com/dtjdx9mec/image/upload/v1685555491/agi_logo_rvwp1o.webp" alt="logo" />

        </span> AGI Controls
    </div>
  )
}
